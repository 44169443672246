"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayConfigBuilder = void 0;
/**
 * Builder to create a config data object which defines information for ad-hoc updates or a specific play
 *
 * @see [[ISharedPlayer.updateConfig]]
 * @see [[ISharedPlayer.play]]
 */
var PlayConfigBuilder = /** @class */ (function () {
    function PlayConfigBuilder() {
        this._data = {};
    }
    PlayConfigBuilder.create = function () {
        return new PlayConfigBuilder();
    };
    /**
     * Specify data for the various player features
     */
    PlayConfigBuilder.prototype.withFeatures = function (features) {
        this._data.features = features;
        return this;
    };
    // /**
    //  * Specify data to configure the captions handling.
    //  * @see {@link PlayerConfigCaptionsData}
    //  */
    // withCaptions(captions: DeepPartial<PlayerConfigCaptionsData>): this {
    //   // TODO:
    //   return this;
    // }
    // /**
    //  * Specify data for the Comscore analytics module
    //  */
    // public withComscore(comscore: ComscoreConfig): this {
    //   // TODO:
    //   return this;
    // }
    // /**
    //  * Specify data for the Open Measurement analytics module
    //  */
    // public withOpenMeasurement(openMeasurement: OpenMeasurementConfig): this {
    //   // TODO:
    //   return this;
    // }
    /**
     * Specify data to configure the analytics section of configuration.
     *
     * @param value whether we should suppress ad reporting or not.
     * @see {@link PlayerConfigAnalyticsData}
     */
    PlayConfigBuilder.prototype.withSuppressAdAnalytics = function (value) {
        this._data.analytics = this._data.analytics || {};
        this._data.analytics.suppressAdAnalytics = value;
        return this;
    };
    /**
     * Specify data for the Conviva analytics module.
     *
     * @see {@link ConvivaAnalyticsConfig.applicationName}
     * @see {@link ConvivaAnalyticsConfig.customerKey}
     * @see {@link ConvivaAnalyticsConfig.touchstoneUrl}
     * @see {@link ConvivaAnalyticsConfig.assetName}
     * @see {@link ConvivaAnalyticsConfig.tags}
     * @see {@link ConvivaAnalyticsConfig.viewerId}
     */
    PlayConfigBuilder.prototype.withConviva = function (conviva) {
        this._data.analytics = this._data.analytics || {};
        this._data.analytics.conviva = conviva;
        return this;
    };
    PlayConfigBuilder.prototype.withMux = function (muxConfig) {
        var _a;
        var _b;
        (_a = (_b = this._data).analytics) !== null && _a !== void 0 ? _a : (_b.analytics = {});
        this._data.analytics.mux = muxConfig;
        return this;
    };
    /**
     * Specify data for ads
     */
    PlayConfigBuilder.prototype.withAds = function (ads) {
        this._data.ads = ads;
        return this;
    };
    /**
     * Specify data for CDN & DRM authentication
     */
    PlayConfigBuilder.prototype.withAuth = function (auth) {
        this._data.auth = auth;
        return this;
    };
    /**
     * Specify data for content entry management
     */
    PlayConfigBuilder.prototype.withCms = function (cms) {
        this._data.cms = cms;
        return this;
    };
    /**
     * Specify data for DRM Playback
     */
    PlayConfigBuilder.prototype.withDrm = function (drm) {
        this._data.drm = drm;
        return this;
    };
    /**
     * Specify data for controlling media playback
     */
    PlayConfigBuilder.prototype.withMedia = function (media) {
        this._data.media = media;
        return this;
    };
    /**
     * Specify configuration data related to all players
     */
    PlayConfigBuilder.prototype.withPlayback = function (playback) {
        this._data.playback = playback;
        return this;
    };
    /**
     * Specify configuration data for monitors
     */
    PlayConfigBuilder.prototype.withMonitors = function (monitors) {
        this._data.monitors = monitors;
        return this;
    };
    /**
     * Specify additional player data
     */
    PlayConfigBuilder.prototype.withMetadata = function (metadata) {
        this._data.metadata = metadata;
        return this;
    };
    PlayConfigBuilder.prototype.withLifecycle = function (lifecycle) {
        this._data.lifecycle = lifecycle;
        return this;
    };
    /**
     * Specify data for the internal player UI
     */
    PlayConfigBuilder.prototype.withUI = function (ui) {
        this._data.ui = ui;
        return this;
    };
    /**
     * Specify mock data
     */
    PlayConfigBuilder.prototype.withMocks = function (mocks) {
        this._data.mocks = mocks;
        return this;
    };
    /**
     * Specify data for debug mode
     */
    PlayConfigBuilder.prototype.withDebug = function (debug) {
        this._data.debug = debug;
        return this;
    };
    PlayConfigBuilder.prototype.withTweaks = function (tweaks) {
        this._data.tweaks = tweaks;
        return this;
    };
    // withAnalytics(analytics: DeepPartial<PlayerConfigAnalyticsData>): this {
    //   this._data.analytics = analytics;
    //   return this;
    // }
    /**
     * Build the ad-hoc/play config data
     */
    PlayConfigBuilder.prototype.build = function () {
        return this._data;
    };
    return PlayConfigBuilder;
}());
exports.PlayConfigBuilder = PlayConfigBuilder;
